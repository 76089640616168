import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../front-page.css"

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout>
      <Seo title="Alle artikler" />
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug}>
              <article
                className="front-page"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2 style={{ marginBottom: "20px !important" }}>
                    <Link to={post.fields.slug} itemProp="url">
                      <span
                        style={{ color: "var(--text)" }}
                        itemProp="headline"
                      >
                        {title}
                      </span>
                    </Link>
                  </h2>
                </header>

                <section>
                  <p
                    style={{ display: "inline" }}
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                  <Link
                    style={{ marginLeft: "8px" }}
                    to={post.fields.slug}
                    itemProp="url"
                  >
                    Læs mere..
                  </Link>
                </section>
              </article>
              <hr style={{ marginBottom: "20px", marginTop: "20px" }} />
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          seotitle
        }
      }
    }
  }
`
